
import React from "react";
import { Button, Form, Input, Space, NavBar, Toast, } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { userSignupAsync, } from "../service/service"

export default (props) => {

    const navigate = useNavigate();
    const [form] = Form.useForm()


    return (
        <div style={{ paddingTop: "0.5rem" }}>
            <NavBar
                style={{
                    marginLeft: 12,
                    marginRight: 12,
                    boxSizing: "border-box",
                    background: "#fff",
                    borderRadius: 8,
                }}
                onBack={() => navigate("/login")}
            >注册</NavBar>
            <Form
                layout='vertical'
                mode='card'
                form={form}
            >
                <Form.Item
                    label='手机号'
                    name="phoneNumber"
                    rules={[
                        { required: true, message: "请输入手机号" },
                        {
                            validator: ($, value) => {
                                let reg = /^1[3-9][0-9]{9}$/
                                if (value && !reg.test(value)) {
                                    return Promise.reject(new Error('请输入正确格式的手机号'))
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <Input placeholder='请输入用户名' />
                </Form.Item>
                <Form.Item
                    label='姓名'
                    name="name"
                    rules={[
                        { required: true, message: "请输入姓名" },
                        {
                            validator: ($, value) => {
                                if (value && value.length > 10) {
                                    return Promise.reject(new Error('姓名字符长度不能超个10'))
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <Input placeholder='请输入姓名' />
                </Form.Item>
                <Form.Item
                    label='密码'
                    name="password"
                    rules={[
                        { required: true, message: "请输入密码" },
                        {
                            validator: ($, value) => {
                                let reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,8}$/
                                if (value && !reg.test(value)) {
                                    return Promise.reject(new Error('请输入6至8位数字字母组合'))
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <Input placeholder='6至8位数字字母组合' type='password' />
                </Form.Item>
            </Form>
            <Space direction="vertical" style={{ width: "100vw", padding: 12, boxSizing: "border-box" }}>
                <Button
                    block
                    color="primary"
                    style={{ marginTop: 10, }}
                    onClick={() => {
                        form.validateFields().then(async (values) => {
                            const res = await userSignupAsync(values);
                            if (res && res.retCode === "true") {
                                Toast.show({
                                    icon: 'success',
                                    content: '注册成功',
                                    afterClose: () => {
                                        navigate("/login")
                                    },
                                })
                            } else {
                                Toast.show({
                                    icon: 'fail',
                                    content: '注册失败',
                                })
                            }
                        }).catch(e => {
                            
                        })
                    }}
                >注册</Button>
            </Space>
        </div>
    );
}

