
import React, { useState, useEffect, useRef } from "react";
import {
    Dialog,
    Empty,
    Button,
    Card,
    Divider,
    Space,
    Popup,
    Form,
    Input,
    Dropdown,
    Grid,
    Radio,
    DatePicker,
    Toast,
    PickerView,
    CheckList,
    List,
    PullToRefresh,
    InfiniteScroll,
} from "antd-mobile";
import "./index.css";
import dayjs from 'dayjs'
import { addAsync, findByConditionsAsync, updateAsync, deleteByIdAsync, getQueryAsync } from "../service/service";
import _ from "lodash";

export default (props) => {
    const ref1 = useRef();
    const { setVisibleData, visibleData } = props;
    const [listData, setListData] = useState([]);

    const [activeKey, setActiveKey] = useState();
    // 还款方式过滤
    const [filterType, setFilterType] = useState([]);

    // 产品过滤
    const [filterProduct, setFilterProduct] = useState([]);
    
    // 时间过滤
    const [filterDate, setFilterDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [selectedDate, setSelectedDate] = useState([dayjs().get("year"), dayjs().get("month"), dayjs().get("date")])
    useEffect(() => {
        if (activeKey === "date") { 
            setSelectedDate([dayjs(filterDate).get("year"), dayjs(filterDate).get("month"), dayjs(filterDate).get("date")])
        }
        if (activeKey === "product") {
            setFilterProduct(filterProduct);
        }

        if (activeKey === "type") { 
            setFilterType(filterType)
        }

    }, [activeKey])
   
    const [hasMore, setHasMore] = useState(true)
    const queryList = async (startIndex) => {
        try {
            const res = await findByConditionsAsync({
                repaymentTime: filterDate,
                product: _.join(filterProduct, ","),
                loanType: _.join(filterType, ","),
                startIndex: startIndex ? startIndex : 0,
            });
            setListData(res && res.data || [])
        } finally {
            //
        }
        
    }

    useEffect(() => {
        if (filterDate) {
            queryList();
        }
    }, [filterDate, filterProduct, filterType])

    const [dateData, setDateData] = useState([])
    useEffect(() => {
        const years = []
        for (let index = 1900; index < 2100; index++) {
            years.push({ label: `${index}年`, value: index, })
        }
        const months = [
            { label: '1月', value: 0 },
            { label: '2月', value: 1 },
            { label: '3月', value: 2 },
            { label: '4月', value: 3 },
            { label: '5月', value: 4 },
            { label: '6月', value: 5 },
            { label: '7月', value: 6 },
            { label: '8月', value: 7 },
            { label: '9月', value: 8 },
            { label: '10月', value: 9 },
            { label: '11月', value: 10 },
            { label: '12月', value: 11 },
        ];

        let myDays = [];
        if (selectedDate[0] && (selectedDate[1] || selectedDate[1] === 0)) {
            const lastDay = new Date(selectedDate[0], selectedDate[1] + 1, 0);
            const days = lastDay.getDate();
            const myDates = [];
            for (let index = 0; index < days; index++) {
                myDates.push(index + 1);
            }
            myDays = _.chain(myDates).map(item => ({ label: `${item}日`, value: item, })).value()
        }
        setDateData([years, months, myDays]);
    }, [JSON.stringify(selectedDate)])

    // 产品还款方式
    const [dic1, setDic1] = useState([]);
    const [dic2, setDic2] = useState([]);
    const getQuery1 = async () => {
        const res = await getQueryAsync({ key: "product" });
        setDic1(res?.data || [])
    }
    const getQuery2 = async () => {
        const res = await getQueryAsync({ key: "loan_type" });
        setDic2(res?.data || [])
    }
    useEffect(() => {
        getQuery1();
        getQuery2();
    }, [])

    const userInfo = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    
    return (
        <div style={{ overflow: "auto", flex: 1, display: "flex", flexDirection: "column" }}>
            <div style={{
                flex: "none",
                borderBottom: "solid 1px rgb(245, 247, 243)",
                boxShadow: "0 -2px  3px -2px rgb(245, 247, 243)",
            }}>
                <Dropdown
                    ref={ref1}
                    activeKey={activeKey}
                    onChange={activeKey => {
                        setActiveKey(activeKey)
                }}>
                    <Dropdown.Item key='date' title={`${filterDate}`} destroyOnClose>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ flex: 1, }}>
                                <PickerView
                                    columns={dateData}
                                    value={selectedDate}
                                    mouseWheel
                                    onChange={(val, extend) => {
                                        setSelectedDate(val)
                                    }}
                                />
                            </div>
                            <Divider style={{ marginBottom: 0 }} />
                            <div style={{ flex: "none", display: "flex", justifyContent: "space-between" }}>
                                <Button
                                    color='primary'
                                    fill='none'
                                    onClick={() => {
                                        setFilterDate(filterDate);
                                        ref1.current?.close();
                                    }}
                                >取消</Button>
                                <Button
                                    color='primary'
                                    fill='none'
                                    onClick={() => {
                                        setFilterDate(dayjs(`${selectedDate[0]}-${selectedDate[1]+1}-${selectedDate[2]}`).format("YYYY-MM-DD"))
                                        ref1.current?.close()
                                    }}
                                >确定</Button>
                            </div>

                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                        key='product'
                        title={`${filterProduct.length > 0 ? filterProduct[0] : "产品"}`}
                        destroyOnClose
                    >
                        <div style={{ display: "flex", flexDirection: "column", maxHeight: 300, overflow: "auto" }}>
                            <div style={{ flex: 1, }}>
                                <CheckList
                                    value={filterProduct}
                                    onChange={value => {
                                        setFilterProduct(value)
                                        ref1.current?.close();
                                    }}
                                >
                                    {_.map(dic1, item => (
                                        <CheckList.Item key={item} value={item}>
                                            {item}
                                        </CheckList.Item>
                                    ))}
                                </CheckList>
                            </div>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item key='type' title={`${filterType.length > 0 ? filterType[0] : "还款方式"}`} destroyOnClose>
                        <div style={{ display: "flex", flexDirection: "column", maxHeight: 300, overflow: "auto" }}>
                            <div style={{ flex: 1, }}>
                                <CheckList
                                    value={filterType}
                                    onChange={value => {
                                        setFilterType(value)
                                        ref1.current?.close();
                                    }}
                                >
                                    {_.map(dic2, item => (
                                        <CheckList.Item key={item} value={item}>
                                            {item}
                                        </CheckList.Item>
                                    ))}
                                </CheckList>
                            </div>
                        </div>
                    </Dropdown.Item>
                </Dropdown>
            </div>
            {listData?.length > 0 ? (
                <div className="scrollStyle" style={{ flex: 1, overflow: "auto", paddingLeft: 4, paddingRight: 4, }}>
                    {
                        _.map(listData, item => {
                            return (
                                <div
                                    key={item.id}
                                    style={{ marginTop: 3, marginBottom: 3, }}
                                    onClick={() => userInfo?.role === "admin" && setVisibleData({ visible: true, data: item, })}
                                >
                                    <Card title={item.customer}>
                                        <Grid columns={2} gap={8}>
                                            <Grid.Item>
                                                <div>{`客户名字: ${item.customer}`}</div>
                                            </Grid.Item>
                                            <Grid.Item>
                                                <div>{`运营经理: ${item.managerId}`}</div>
                                            </Grid.Item>
                                            <Grid.Item>
                                                <div>{`放款产品: ${item.product}`}</div>
                                            </Grid.Item>
                                            <Grid.Item>
                                                <div>{`放款金额: ${item.loanAmount}`}</div>
                                            </Grid.Item>
                                            <Grid.Item>
                                                <div>{`放款时间: ${item.loanTime}`}</div>
                                            </Grid.Item>
                                            <Grid.Item>
                                                <div>{`放款期数: ${item.loanIndex}期`}</div>
                                            </Grid.Item>
                                            <Grid.Item>
                                                <div>{`还款时间: ${item.repaymentTime}`}</div>
                                            </Grid.Item>
                                            <Grid.Item>
                                                <div>{`还款期数: 第${item.repayIndex}期`}</div>
                                            </Grid.Item>
                                            <Grid.Item>
                                                <div>{`还款方式: ${item.loanType}`}</div>
                                            </Grid.Item>
                                            <Grid.Item>
                                                <div>{`月供金额: ${item.payment}元`}</div>
                                            </Grid.Item>
                                        </Grid>
                                    </Card>
                                </div>
                            )
                        })
                    }
                    <InfiniteScroll
                        hasMore={hasMore}
                        loadMore={async () => {
                            try {
                                const res = await findByConditionsAsync({
                                    repaymentTime: filterDate,
                                    product: _.join(filterProduct, ","),
                                    loanType: _.join(filterType, ","),
                                    startIndex: _.max(_.map(listData, item => item.id)) || 0,
                                });
                                setListData(_.uniqBy(_.concat(listData, res?.data || []), ele => ele.id))
                                setHasMore(res?.data?.length > 0)
                            } finally {
                                //
                            }
                        }}
                    />
                </div>
            ) : (
                <div style={{ background: "#fff", flex: 1, paddingLeft: 4, paddingRight: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Empty description='暂无数据' />
                </div>
            )}

            <Popup
                position='right'
                visible={visibleData.visible}
                showCloseButton
                destroyOnClose
                bodyStyle={{ width: '100vw' }}
                onClose={() => {
                    setVisibleData({ visible: false, data: null, })
                }}
            >
                <Form
                    style={{ padding: "40px 20px 20px" }}
                    layout='horizontal'
                    initialValues={visibleData?.data ? {
                        customer: visibleData.data.customer,
                        product: visibleData.data.product,
                        loanAmount: visibleData.data.loanAmount,
                        loanTime: new Date(visibleData.data.loanTime),
                        repaymentTime: new Date(visibleData.data.repaymentTime),
                        loanIndex: visibleData.data.loanIndex,
                        loanType: visibleData.data.loanType,
                        payment: visibleData.data.payment,
                    } : null}
                    onFinish={async (values) => {
                        let res;
                        if (visibleData?.data) { 
                            res = await updateAsync({
                                ...values,
                                loanTime: dayjs(values.loanTime).format('YYYY-MM-DD'),
                                repayIndex: visibleData.data.repayIndex,
                                repaymentTime: dayjs(values.repaymentTime).format('YYYY-MM-DD'),
                                id: visibleData.data.id,
                                loanId: visibleData.data.loanId,
                            });
                        } else {
                            res = await addAsync({
                                ...values,
                                loanTime: dayjs(values.loanTime).format('YYYY-MM-DD'),
                                repaymentTime: dayjs(values.repaymentTime).format('YYYY-MM-DD'),
                            });
                        }
                        
                        if (res && res.retCode === "true") {
                            Toast.show({
                                icon: 'success',
                                content: `${visibleData.data ? "修改" : "新增"}成功`,
                                afterClose: () => {
                                    setVisibleData({ visible: false, data: null, })
                                    queryList();
                                },
                            });
                        } else {
                            Toast.show({
                                icon: 'fail',
                                content: `${visibleData.data ? "修改" : "新增"}失败`,
                            })
                        }

                    }}
                    footer={
                        <Space direction="vertical" style={{width: "100%"}}>
                            <Button block color='primary' type='submit'>
                                提交
                            </Button>
                            {visibleData.data && <Button
                                block
                                color='primary'
                                onClick={() => {
                                    Dialog.confirm({
                                        content: '确定删除吗?',
                                        onConfirm: async () => {
                                            const res = await deleteByIdAsync({
                                                id: visibleData.data.id,
                                                loanId: visibleData.data.loanId,
                                            });
                                            if (res && res.retCode === "true") {
                                                Toast.show({
                                                    icon: 'success',
                                                    content: '删除成功',
                                                    afterClose: () => {
                                                        setVisibleData({ visible: false, data: null });
                                                        queryList();
                                                    },
                                                });
                                            } else {
                                                Toast.show({
                                                    icon: "fail",
                                                    content: "删除失败",
                                                });
                                            }
                                        },
                                    })
                                }}
                            >
                                删除
                            </Button>}
                        </Space>
                    }
                >
                    <Form.Item label='客户名字' name="customer" rules={[{ required: true, message: '客户名字不能为空' }]}>
                        <Input placeholder='请输入客户名字' />
                    </Form.Item>

                    <Form.Item label='放款产品' name="product" rules={[{ required: true, message: '放款产品不能为空' }]}>
                        <Input placeholder='请输入放款产品' />
                    </Form.Item>
                    <Form.Item label='放款金额' name="loanAmount" rules={[{ required: true, message: '放款金额不能为空' }]}>
                        <Input placeholder='请输入放款金额' />
                    </Form.Item>
                    <Form.Item
                        label='放款时间'
                        name="loanTime"
                        trigger='onConfirm'
                        onClick={(e, datePickerRef) => {
                            datePickerRef.current?.open()
                        }}
                        rules={[{ required: true, message: '放款时间不能为空' }]}>
                        <DatePicker>
                            {value =>
                                value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
                            }
                        </DatePicker>
                    </Form.Item>
                    
                    <Form.Item label='放款期数' name="loanIndex" rules={[{ required: true, message: '放款期数不能为空' }]}>
                        <Input placeholder='请输入放款期数' />
                    </Form.Item>
                    <Form.Item
                        label='还款时间'
                        name="repaymentTime"
                        trigger='onConfirm'
                        onClick={(e, datePickerRef) => {
                            datePickerRef.current?.open()
                        }}
                        rules={[{ required: true, message: '还款时间不能为空' }]}>
                        <DatePicker>
                            {value =>
                                value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
                            }
                        </DatePicker>
                    </Form.Item>
                    <Form.Item label='还款方式' name="loanType" rules={[{ required: true, message: '还款方式不能为空' }]}>
                        <Input placeholder='请输入还款方式' />
                    </Form.Item>
                    <Form.Item label='月供金额' name="payment" rules={[{ required: true, message: '月供金额不能为空' }]}>
                        <Input placeholder='请输入月供金额' />
                    </Form.Item>
                </Form>
            </Popup>
        </div>
    );
}

