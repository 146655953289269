import axios from 'axios';
import { Toast } from 'antd-mobile';


const service = axios.create({
    headers: {
        token: localStorage.getItem('token') || '',
        "Content-Type": "application/json",
    },
    baseURL: "/front/api",
    timeout: 1 * 60 * 1000,
    withCredentials: true,
});

// 请求前拦截
service.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        config.headers.token = token || '';
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 响应拦截
service.interceptors.response.use(
    response => {
        if (response.data.retCode === "9998") { 
            localStorage.clear();
            Toast.show({
                content: `${response.data.retMessage}!`,
                maskClickable: false,
            })
            window.location.href = "/#/login";
            return Promise.reject();
        }
        if (response.data.retCode === "true") {
            return Promise.resolve(response.data);
        } else {
            Toast.show({
                content: `${response.data.retMessage}!`,
                maskClickable: false,
            })
            return Promise.reject();
        }
        
    },
    error => {
        
        if (error.response.status) {
            switch (error.response.status) {
                case 401:
                    Toast.show({
                        content: `${error.response.data.retMessage}!`,
                        maskClickable: false,
                    })
                    localStorage.clear();
                    window.location.href = "/#/login";
                    break;
                
                case 403:
                    Toast.show({
                        content: `${error.response.data.retMessage}!`,
                        maskClickable: false,
                    })
                    localStorage.clear();
                    window.location.href = "/#/login";
                    break;
                
                case 404:
                    Toast.show({
                        content: '网络请求不存在',
                        maskClickable: false,
                    })
                    break;
                
                default:
                    Toast.show({
                        content: error.response.data.retMessage,
                        maskClickable: false,
                    })
            }
        } else {
            if (JSON.stringify(error).includes("timeout")) { 
                Toast.show({
                    content: '服务器请求超时!',
                    maskClickable: false,
                })
            }
        }
        return Promise.reject(error)
    }
)

export const postRequest = ({ url, params }) => {
    return service({
        url: url,
        method: "post",
        data: params,
    }).catch(e => {
        
    })
}

export const getRequest = ({ url, params }) => {
    return service({
        url: url,
        method: "get",
        params,
    }).catch(e => {

    })
}