import React, { useState, useEffect } from "react";
import { TabBar, NavBar } from "antd-mobile";
import { UnorderedListOutline, UserOutline, AddOutline } from "antd-mobile-icons";
import "./index.css";
import _ from "lodash";
import ListView from "./ListView";
import MyInfo from "./MyInfo";

export default (props) => {
  const [visibleData, setVisibleData] = useState({
    visible: false,
    data: null,
  });

  const tabs = [
    {
      key: "1",
      title: "客户信息",
      icon: <UnorderedListOutline />,
    },
    {
      key: "2",
      title: "我的信息",
      icon: <UserOutline />,
    },
  ];

  const [activeKey, setActiveKey] = useState("1");

  return (
    <div className="home">
      <div style={{
        flex: "none",
        borderBottom: "solid 1px rgb(245, 247, 243)",
        boxShadow: "0 2px 3px -2px rgb(245, 247, 243)",
        background: "#fff",
      }}
      >
        <NavBar back={null} right={activeKey === "1" && <AddOutline fontSize={24} onClick={() => setVisibleData({ visible: true, data: null, })} />}>
          <span style={{fontSize: 18,}}>{activeKey === "1" ? "客户信息" : "我的信息"}</span>
        </NavBar>
      </div>
      <div style={{overflow: "auto", flex: 1, display: "flex", flexDirection: "column", }}>
        {activeKey === "1" ? <ListView setVisibleData={setVisibleData} visibleData={visibleData} /> : <MyInfo />}
      </div>
      <div
        style={{
          flex: "none",
          borderTop: "solid 1px rgb(245, 247, 243)",
          boxShadow: "0 -2px  3px -2px rgb(245, 247, 243)",
          background: "#fff",
        }}
      >
        <TabBar activeKey={activeKey} onChange={setActiveKey}>
          {_.map(tabs, (item) => (
            <TabBar.Item
              key={item.key}
              icon={item.icon}
              title={item.title}
            />
          ))}
        </TabBar>
      </div>
    </div>
  );
};
