
import React, { useState, useEffect } from "react";
import { Button, Form, Space, Toast, Popup, Input } from "antd-mobile";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { loginOutAsync, updatePasswordAsync } from "../service/service";

export default (props) => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    return (
        <div style={{ overflow: "auto", flex: 1, display: "flex", flexDirection: "column", background:"#fff" }}>
            <Form
                layout='vertical'
                mode='card'
                onFinish={async () => {
                    Toast.show({
                        icon: "success",
                        content: "退出成功",
                        afterClose: () => {
                            localStorage.clear();
                            navigate("/login");
                        },
                    });
                    const res = await loginOutAsync();
                    // if (res && res.retCode === "true") {
                    //     Toast.show({
                    //         icon: "success",
                    //         content: "退出成功",
                    //         afterClose: () => {
                    //             // localStorage.clear();
                    //             // navigate("/login");
                    //         },
                    //     });
                    // } else {
                    //     Toast.show({
                    //         icon: "fail",
                    //         content: "退出失败",
                    //     });
                    // }
                }}
                footer={
                    <Space direction="vertical" style={{width: "100%"}}>
                        <Button
                            block
                            color='primary'
                            onClick={() => setVisible(true)}
                        >
                            重置密码
                        </Button>
                        <Button block color='primary' type='submit'>
                            退出登录
                        </Button>
                    </Space>
                  
                }
            >
                <Form.Item label='姓名'>
                    {localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).name : ""}
                </Form.Item>
                <Form.Item label='手机号'>
                    {localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).phoneNumber : ""}
                </Form.Item>
            </Form>

            <Popup
                position='right'
                visible={visible}
                showCloseButton
                destroyOnClose
                bodyStyle={{ width: '100vw' }}
                onClose={() => {
                    setVisible(false)
                }}
            >
                <Form
                    style={{ padding: "40px 20px 20px" }}
                    layout='horizontal'
                    onFinish={async (values) => {
                        const res = await updatePasswordAsync(values);
                        if (res && res.retCode === "true") {
                            Toast.show({
                                icon: "success",
                                content: "更改密码成功",
                                afterClose: () => {
                                    localStorage.clear();
                                    navigate("/login");
                                },
                            });
                        } else {
                            Toast.show({
                                icon: "fail",
                                content: "更改密码失败",
                            });
                        }
                    }}
                    footer={
                        <Button block color='primary' type='submit'>
                            提交
                        </Button>
                    }
                >
                    <Form.Item label='旧密码' name="oldPassword" rules={[{ required: true, message: '旧密码不能为空' }]}>
                        <Input placeholder='请输入旧密码' />
                    </Form.Item>

                    <Form.Item label='新密码' name="newPassword"
                        rules={[
                            { required: true, message: '新密码不能为空' },
                            {
                                validator: ($, value) => {
                                    let reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,8}$/
                                    if (value && !reg.test(value)) {
                                        return Promise.reject(new Error('请输入6至8位数字字母组合'))
                                    }
                                    return Promise.resolve();
                                }
                            }
                        ]}
                    >
                        <Input placeholder='请输入新密码' />
                    </Form.Item>
                </Form>
            </Popup>
        </div>
    );
}

