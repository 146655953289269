import React, { useState } from "react";
import { Button, Form, Input, Space, Toast, Popup, } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { userLoginAsync, resetPasswordAsync } from "../service/service";

export default (props) => {
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const [visible, setVisible] = useState(false)

	return (
		<div style={{ paddingTop: "0.5rem" }}>
			<Form layout="vertical" mode="card" form={form}>
				<Form.Item
					label="手机号"
					name="phoneNumber"
					rules={[
						{ required: true, message: "请输入手机号" },
						{
							validator: ($, value) => {
								let reg = /^1[3-9][0-9]{9}$/
								if (value && !reg.test(value)) {
									return Promise.reject(new Error('请输入正确格式的手机号'))
								}
								return Promise.resolve();
							}
						}
					]}
				>
					<Input placeholder="请输入手机号" />
				</Form.Item>
				<Form.Item
					label="密码"
					name="password"
					rules={[
						{ required: true, message: "请输入密码" },
						{
							validator: ($, value) => {
								let reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,8}$/
								if (value && !reg.test(value)) {
									return Promise.reject(new Error('请输入6至8位数字字母组合'))
								}
								return Promise.resolve();
							}
						}
					]}
				>
					<Input placeholder="6至8位数字字母组合" type="password" />
				</Form.Item>
			</Form>
			<Space
				direction="vertical"
				style={{ width: "100vw", padding: 12, boxSizing: "border-box" }}
			>
				<Button
					block
					color="primary"
					style={{ marginTop: 10 }}
					onClick={() => {
						form.validateFields()
							.then(async (values) => {
								const res = await userLoginAsync(values);
								if (res && res.retCode === "true") {
									Toast.show({
										icon: "success",
										content: "登录成功",
										afterClose: () => {
											localStorage.setItem(
												"token",
												res.data.token
											);
											localStorage.setItem(
												"user",
												JSON.stringify(res.data)
											);
											navigate("/home");
										},
									});
								} else {
									Toast.show({
										icon: "fail",
										content: "登录失败",
									});
								}
							})
							.catch((e) => {});
					}}
				>
					登录
				</Button>
				<Button
					block
					color="primary"
					style={{ marginTop: 10 }}
					onClick={() => navigate("/registor")}
				>
					注册
				</Button>
				<span style={{ display: "flex", justifyContent: "flex-end" }}>
					<span onClick={() => setVisible(true)}>忘记密码?</span>
				</span>
			</Space>

			<Popup
				position='right'
				visible={visible}
				showCloseButton
				destroyOnClose
				bodyStyle={{ width: '100vw' }}
				onClose={() => {
					setVisible(false)
				}}
			>
				<Form
					style={{ padding: "40px 20px 20px" }}
					layout='horizontal'
					onFinish={async (values) => {
						const res = await resetPasswordAsync({
							...values,
						});
						
						if (res && res.retCode === "true") {
							Toast.show({
								icon: 'success',
								content: `重置密码成功`,
								afterClose: () => {
									setVisible(false)
								},
							})
						} else {
							Toast.show({
								icon: 'fail',
								content: `重置密码失败`,
							})
						}

					}}
					footer={
						<Button block color='primary' type='submit'>
							提交
						</Button>
					}
				>
					<Form.Item label='姓名' name="name"
						rules={[
							{ required: true, message: '请输入姓名' },
							{
								validator: ($, value) => {
									if (value && value.length > 10) { 
										return Promise.reject(new Error('姓名字符长度不能超个10'))
									}
									return Promise.resolve();
								}
							}
						]}
					>
						<Input placeholder='请输入姓名' />
					</Form.Item>
					<Form.Item label='手机号' name="phoneNumber"
						rules={[
							{ required: true, message: '请输入手机号' },
							{
								validator: ($, value) => {
									let reg = /^1[3-9][0-9]{9}$/
									if (value && !reg.test(value)) {
										return Promise.reject(new Error('请输入正确格式的手机号'))
									}
									return Promise.resolve();
								}
							}
						]}
					>
						<Input placeholder='请输入手机号' />
					</Form.Item>
					<Form.Item label='密码' name="password"
						rules={[
							{ required: true, message: '请输入密码' },
							{
								validator: ($, value) => {
									let reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,8}$/
									if (value && !reg.test(value)) {
										return Promise.reject(new Error('请输入6至8位数字字母组合'))
									}
									return Promise.resolve();
								}
							}
						]}>
						<Input placeholder='6至8位数字字母组合' />
					</Form.Item>
				</Form>
			</Popup>
		</div>
	);
};
