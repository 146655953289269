import React, { useEffect } from "react"
import { Navigate, useLocation} from "react-router-dom";
import routes from './router';
import _ from "lodash";
import Login from "./pages/Login";

export default (props) => {
  const location = useLocation();
  const { pathname } = location;
  const isLogin = localStorage.getItem("token");
  // 匹配的路由
  const inputPath = _.find(routes, item => item.path === pathname);

  if (isLogin) { 
    if (pathname === "/login") {
      return <Navigate to={"/home"} />
    } else {
      if (inputPath) {
        return <Navigate to={pathname} />
      }
      return <Navigate to="/404" />
    }
  } else {
    if (inputPath) { 
      return <Navigate to="/login" />
    }
    return <Navigate to="/404" />
  }
}
