
import App from "../App";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Registor from "../pages/Registor";

export default [
    {
        path: "/",
        name: "app",
        component: <App />,
    },
    {
        path: "/login",
        name: "login",
        component: <Login />,
    },
    {
        path: "/registor",
        name: "registor",
        component: <Registor />,
    },
    {
        path: "/home",
        name: "home",
        component: <Home/>,
    },
]