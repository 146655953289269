import { postRequest, getRequest }  from "../util/request";

// 注册
const userSignupAsync = async (params) => {
    const res = await postRequest({
        url: "/user/signup",
        params,
    })
    return res;
}

//登录
const userLoginAsync = async (params) => {
    const res = await postRequest({
        url: "/user/login",
        params,
    })
    return res;
}

//更改密码
const updatePasswordAsync = async (params) => {
    const res = await postRequest({
        url: "/user/updatePassword",
        params,
    })
    return res;
}

//重置密码（忘记密码）
const resetPasswordAsync = async (params) => {
    const res = await postRequest({
        url: "/user/resetPassword",
        params,
    })
    return res;
}

//退出登录
const loginOutAsync = async (params) => {
    const res = await getRequest({
        url: "/user/loginOut",
        params,
    })
    return res;
}

// 查列表
const findByConditionsAsync = async (params) => {
    const res = await postRequest({
        url: "/loan/findByConditions",
        params,
    })
    return res;
}

// 增加
const addAsync = async (params) => {
    const res = await postRequest({
        url: "/loan/add",
        params,
    })
    return res;
}

// 修改
const updateAsync = async (params) => {
    const res = await postRequest({
        url: "/loan/update",
        params,
    })
    return res;
}

// 删除
const deleteByIdAsync = async (params) => {
    const res = await postRequest({
        url: "/loan/deleteById",
        params,
    })
    return res;
}

// 查询产品或还款方式
const getQueryAsync = async (params) => {
    const res = await getRequest({
        url: "/loan/getQuery",
        params,
    })
    return res;
}

export {
    userSignupAsync,
    userLoginAsync,
    updatePasswordAsync,
    resetPasswordAsync,
    loginOutAsync,
    findByConditionsAsync,
    addAsync,
    updateAsync,
    deleteByIdAsync,
    getQueryAsync,
}